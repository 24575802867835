import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { getCollectorOnCase } from '@debbie/common/dist/cases'
import * as _collectors from '@debbie/common/dist/collectors'
import { Plan } from '@debbie/common/dist/economy/payment-plans'
import { h } from '@debbie/cortex/dist'
import * as paymentPlansAPI from '@debbie/cortex/dist/api/payment-plans'
import { selectCollectorById } from '@debbie/cortex/dist/collectors/selectors'
import { selectCreditorById } from '@debbie/cortex/dist/creditors/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import { Button, Panel, Well, Pay } from '@debbie/iris/dist/components'
import AlertCircle from '@debbie/iris/dist/components/Icons/AlertCircle'

import card_dan from '../assets/images/card_dan.png'
import card_master from '../assets/images/card_master.png'
import card_visa from '../assets/images/card_visa.png'
import mobilepay from '../assets/images/mobilepay.png'
import payment_bs from '../assets/images/payment_bs.png'
import payment_ls from '../assets/images/payment_ls.png'

import EntriesList from './EntriesList'

interface Props {
  singleCase: any
  paymentPlan: Plan
}

interface State {
  cvc: string
  cardNumber: string
  expiry: string
  connectCardError: null | string
}

const ConnectCardStep = (props: Props) => {
  const params = useParams<{ id: string }>()
  const { id } = params
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [state, setState] = useState<State>({
    cvc: '',
    cardNumber: '',
    expiry: '',
    connectCardError: null,
  })

  const lang = useSelector((s: RootState) => s.lang)
  const authToken = useSelector((s: RootState) => s.authToken)

  const { singleCase, paymentPlan } = props

  const creditor = useSelector(
    (s: RootState) =>
      singleCase && selectCreditorById(s, singleCase.creditorId),
  )

  const collector = useSelector(
    (s: RootState) =>
      singleCase &&
      creditor &&
      selectCollectorById(
        s,
        getCollectorOnCase(
          singleCase.type,
          singleCase.collectorId,
          creditor.identityCollectorId,
          creditor.defaultCollectorId,
        ),
      ),
  )

  const paymentOptions: _collectors.PaymentOption[] =
    collector &&
    collector.paymentOptions &&
    singleCase &&
    singleCase.currency &&
    _collectors.getPaymentOptions(collector.paymentOptions, singleCase.currency)

  const reepay =
    paymentOptions &&
    paymentOptions.find(
      (paymentOption) =>
        paymentOption.type === _collectors.PaymentOptionType.REEPAY,
    )

  const farpay =
    paymentOptions &&
    paymentOptions.find(
      (paymentOption) =>
        paymentOption.type === _collectors.PaymentOptionType.FARPAY,
    )

  const mobilePeople =
    paymentOptions &&
    paymentOptions.find(
      (paymentOption) =>
        paymentOption.type === _collectors.PaymentOptionType.MOBILE_PEOPLE,
    )

  return (
    <div>
      <Panel>
        {collector && (
          <Pay case={singleCase} collector={collector} isPaymentPlan={true}>
            {((reepay && reepay.isActive) ||
              (farpay && farpay.isActive) ||
              (mobilePeople && mobilePeople.isActive)) && (
              <div>
                <h2>{lang.debtorWebPaymentPlans.automaticPaymentMethod}</h2>
                <p>{lang.debtorWebPaymentPlans.paymentMethodText}</p>
                {state.connectCardError &&
                  typeof state.connectCardError === 'string' && (
                    <>
                      <Well>
                        <AlertCircle /> {state.connectCardError}
                      </Well>
                      <br />
                    </>
                  )}
                {singleCase && collector && h.isLoaded(authToken) && (
                  <div
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      onClick={async () => {
                        setIsLoading(true)
                        try {
                          const link = await paymentPlansAPI.getPSPLink(
                            paymentPlan.paymentPlanId,
                            window.location.href,
                            window.location.href,
                          )

                          location.replace(link.url)
                        } catch (error) {
                          console.error(error)
                          alert('Error. Please try again later.')
                        }
                        setIsLoading(false)
                      }}
                      loading={isLoading}
                    >
                      {lang.debtorWebPaymentPlans.connectCard}
                    </Button>
                    <p
                      style={{
                        textAlign: 'center',
                        marginTop: '15px',
                      }}
                    >
                      {reepay &&
                        reepay.isActive &&
                        reepay.type ===
                          _collectors.PaymentOptionType.REEPAY && (
                          <>
                            {reepay.details.visa && <Card src={card_visa} />}
                            {reepay.details.mastercard && (
                              <Card src={card_master} />
                            )}
                            {reepay.details.dankort && <Card src={card_dan} />}
                          </>
                        )}
                      {farpay &&
                        farpay.isActive &&
                        farpay.type ===
                          _collectors.PaymentOptionType.FARPAY && (
                          <>
                            {farpay.details.visa && <Card src={card_visa} />}
                            {farpay.details.mastercard && (
                              <Card src={card_master} />
                            )}
                            {farpay.details.dankort && <Card src={card_dan} />}
                            {farpay.details.mobilepay && (
                              <Card src={mobilepay} />
                            )}
                            {farpay.details.betalingsservice && (
                              <Card src={payment_bs} />
                            )}
                            {farpay.details.leverandorservice && (
                              <Card src={payment_ls} />
                            )}
                          </>
                        )}
                      {mobilePeople &&
                        mobilePeople.isActive &&
                        mobilePeople.type ===
                          _collectors.PaymentOptionType.MOBILE_PEOPLE && (
                          <>
                            {mobilePeople.details.visa && (
                              <Card src={card_visa} />
                            )}
                            {mobilePeople.details.mastercard && (
                              <Card src={card_master} />
                            )}
                            {mobilePeople.details.dankort && (
                              <Card src={card_dan} />
                            )}
                            {mobilePeople.details.mobilepay && (
                              <Card src={mobilepay} />
                            )}
                            {mobilePeople.details.betalingsservice && (
                              <Card src={payment_bs} />
                            )}
                            {mobilePeople.details.leverandorservice && (
                              <Card src={payment_ls} />
                            )}
                          </>
                        )}
                    </p>
                  </div>
                )}
              </div>
            )}
          </Pay>
        )}
      </Panel>
      <Panel>
        <EntriesList paymentPlan={paymentPlan} currency={singleCase.currency} />
      </Panel>
    </div>
  )
}

export default ConnectCardStep

const Card = styled.img`
  margin: 0 6px;
  height: 25px;
`
